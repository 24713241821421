import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { defineStore, storeToRefs } from 'pinia';

import { isHejia, isWebView, isWechat } from '@eaphone/env';

const getSerial = isHejia
  ? () =>
      import('@eaphone/logic/lib/hejia/store.js').then(({ getSerial: func }) =>
        func(),
      )
  : async () => {};

export const useSettings = defineStore('settings', {
  state: () => ({
    phoneBinding: isWechat,
    hybridSDK: isWebView,
    serial: undefined,
  }),
  actions: {
    setSerial() {
      return getSerial()
        .then((serial) => {
          if (serial && this.serial !== serial) {
            this.serial = serial;
          }
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  },
});

export function useSerial() {
  const store = useSettings();
  const router = useRouter();

  onMounted(() => {
    if (isHejia) {
      store.setSerial().catch((error) => {
        if (import.meta.env.WATCHING) {
          throw error;
        } else if (error.name === 'SdkError') {
          router.replace({ name: 'error-sdk' });
        }
      });
    }
  });

  return computed(() => storeToRefs(store).serial?.value);
}
