import { routes } from '@road-to-rome/routes';

import { createRouter, createWebHistory } from 'vue-router';

import { showFailToast } from 'vant';

import { routeMapper } from '@eaphone/utils';

export const router = createRouter({
  /* globals __webpack_public_path__: readonly */
  history: createWebHistory(__webpack_public_path__),
  routes: [
    ...routeMapper(routes),
    {
      path: '/',
      redirect: { name: 'home' },
    },
  ],
});

router.onError((error) => {
  if (error.name === 'ChunkLoadError') {
    showFailToast('页面加载失败');
  }
});
