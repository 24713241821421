const { userAgent } = navigator;

export const isInWechat = /micromessenger|weixin|wechat/i.test(userAgent);

export const isWechatMiniApp = isInWechat && /miniprogram/i.test(userAgent);

export const isDevtool = /wechatdevtools/i.test(userAgent);

export const isIOS = /ip(?:ad|hone|od)/i.test(userAgent);

export const isAndroid = /android/i.test(userAgent);

export const { is3rdView = false } = window;

export const isHejia = import.meta.env.CONFIG_NAME === 'hejia';

export const isWechat = import.meta.env.CONFIG_NAME === 'wechat';

export const isEHome = import.meta.env.CONFIG_NAME === 'ehome';

export const isWebView = import.meta.env.CONFIG_NAME === 'webview';

export const isEaphone = isWechat || isWebView;
